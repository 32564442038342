
import { defineComponent } from 'vue'

export default defineComponent({

  name: 'Sidebar',

  data() {
    const links: { title: string, url: string }[] = [
      { title: 'MortalArc', url: '/' },
      { title: 'Sample', url: '/sample' },
      { title: 'UI', url: '/ui' },
      { title: 'Annotator', url: '/annotator' },
      { title: 'About', url: '/about' },
    ]
    return {
      links
    }
  },

  computed: {
    windowLocStr() {
      let windowStr = window.location.pathname
      while (windowStr.endsWith('/')) windowStr = windowStr.substr(0, windowStr.length - 1)
      return windowStr
    }
  },

  methods: {
    isLinkEnabled(link: { title: string, url: string }) {
      let linkStr = link.url
      while (linkStr.endsWith('/')) linkStr = linkStr.substr(0, linkStr.length - 1)
      return linkStr !== this.windowLocStr
    }
  }

})
