<template>
<div class="sidenav">
  <ul>
    <li v-for="link in links" :key="link.title">
      <a
        :class="isLinkEnabled(link) ? 'enabled' : 'disabled'"
        :href="link.url"
      >{{ link.title }}</a>
    </li>
  </ul>
</div>
</template>



<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({

  name: 'Sidebar',

  data() {
    const links: { title: string, url: string }[] = [
      { title: 'MortalArc', url: '/' },
      { title: 'Sample', url: '/sample' },
      { title: 'UI', url: '/ui' },
      { title: 'Annotator', url: '/annotator' },
      { title: 'About', url: '/about' },
    ]
    return {
      links
    }
  },

  computed: {
    windowLocStr() {
      let windowStr = window.location.pathname
      while (windowStr.endsWith('/')) windowStr = windowStr.substr(0, windowStr.length - 1)
      return windowStr
    }
  },

  methods: {
    isLinkEnabled(link: { title: string, url: string }) {
      let linkStr = link.url
      while (linkStr.endsWith('/')) linkStr = linkStr.substr(0, linkStr.length - 1)
      return linkStr !== this.windowLocStr
    }
  }

})
</script>



<style scoped>

/* The sidebar menu */
.sidenav {
  height: 100%; /* Full-height: remove this if you want "auto" height */
  /* Width of sidebar set in parent */
  position: fixed; /* Fixed Sidebar (stay in place on scroll) */
  z-index: 1; /* Stay on top */
  top: 0; /* Stay at the top */
  left: 0;
  overflow-x: hidden; /* Disable horizontal scroll */
  padding-top: 20px;
  background-color: var(--color-back);
  color: var(--color-font);
}

.sidenav ul {
  padding: 0pt;
}

/* The navigation menu links */
.sidenav a {
  display: block;
  line-height: 3rem;
  font-size: 1.5rem;
  padding-left: 10pt;
}

</style>
