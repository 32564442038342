<template>
<div class="tabcontent">

  <Sidebar class="sidebar"/>

  <!-- mainsub consists of a header and one or more paragraphs -->
  <div class="notsidebar">

    <h2>Making a map of history</h2>
    <div class=mainpar>
      <p>Watch Napoleon march across Europe.</p>
      <p>Watch him meet generals and politicians.</p>
      <p>Zoom into a small town and watch residents respond to his army's passage.</p>
    </div>
    <div class=mainpar>
      <p>View Japan during the early Edo period.</p>
      <p>Change time, pan across the map, filter to focus on profession, race, gender.</p>
      <p>Watch the migration of citizenry after the great fire of Meireki.</p>
    </div>
    <div class=mainpar>
      <p>Connect to MortalArc's database of references to see a map
        of the information fused from many pre-parsed documents and books.</p>
      <p>Alternatively, feed in a private set of documents for a more
        focused view of a particular subject.</p>
      <p>Click on a person at some place and time to see which sources
        contributed to the information.</p>
    </div>

  </div>

  <div class="notsidebar">
    <h2>About the project</h2>

    <p>This project develops a software that takes in plain text documents and outputs
     an interactive, animated map of people moving across the world. Feed in a book about Napoleon,
     then watch him march across Europe, watch him meet generals and politicians, then zoom into a
     small town and see interactions of lesser known people. Change time,
     pan around, and filter for profession, race, gender.
     Click on a person at some place and time to see which sources
     contributed to the information.</p>

    <p>This work is focused toward an EdTech market. We see educators as the end-user
     for the early stages where parsing and, later, data fusion algorithms are still in flux. We
     could market directly to schools and teachers, supplying the software subscription for
     private use and/or access to our database of pre-parsed documents, or market to textbook
     publishers, packaging specific, tailored visualizations in with textbooks.</p>

    <p>Teachers could use our product to explain
    the broad view of a particular chapter before delving into the details.
    History teachers could particularly benefit, transforming complicated history
    from any number of texts into a simplified
    but informative visualization of the topic they want to discuss.
    Eventually, we will expand our market to reach the general public, possibly marketing as
    a cross between Wikipedia and Google Earth.</p>

  </div>

</div>
</template>



<script lang='ts'>
import { defineComponent } from 'vue'
import { Sidebar } from '../component'


export default defineComponent({

  name: 'Main',

  components: {
    Sidebar
  }

})
</script>



<style scoped>

.mainpar {
  text-align: left;
  border: 5px solid var(--color-light-2);
  margin-top: 30px;
  margin-bottom: 30px;
  margin-left: auto;
  margin-right: auto;
  padding: 10px 10px;
  max-width: 800px;
  border-radius: 10px;
}

.sidebar {
  width: 200pt;
}

.notsidebar {
  margin-left: 200pt; /* Same as the width of the sidebar */
  padding: 0px 10px;
}

</style>
