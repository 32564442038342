
import { defineComponent } from 'vue'
import { Sidebar } from '../component'


export default defineComponent({

  name: 'Main',

  components: {
    Sidebar
  }

})
